import React from 'react';
import { Check } from 'lucide-react';


const APP_URL = process.env.REACT_APP_APP_URL;


const Card = ({ className, children, ...props }) => (
  <div className={`bg-white p-4 rounded-lg shadow-lg ${className}`} {...props}>
    {children}
  </div>
);

const CardHeader = ({ children }) => <div className="mb-2">{children}</div>;
const CardTitle = ({ className, children }) => <h3 className={`text-xl font-bold ${className}`}>{children}</h3>;
const CardContent = ({ children }) => <div>{children}</div>;
const CardFooter = ({ children }) => <div className="mt-4">{children}</div>;


const FeaturesSection = () => {
  return (
    <section id="features" className="w-full py-8 md:py-16 lg:py-20 bg-gray-100">
      <div className="container mx-auto px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <div className="space-y-2">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Key Features</h2>
            <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              Essor empowers HR professionals and company leaders with powerful tools for career framework management.
            </p>
          </div>
        </div>
        <div className="mx-auto grid max-w-5xl items-center gap-8 py-12 md:grid-cols-3">
          <div className="flex flex-col items-center text-center">
            <PencilIcon className="h-12 w-12 mb-4 text-primary" />
            <h3 className="text-xl font-bold mb-2">AI-Generated CPFs</h3>
            <p className="text-muted-foreground">
              Automatically create comprehensive career frameworks tailored to your company's needs.
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <SettingsIcon className="h-12 w-12 mb-4 text-primary" />
            <h3 className="text-xl font-bold mb-2">Framework Customization</h3>
            <p className="text-muted-foreground">
              Easily modify and adapt your CPF as your organization evolves.
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            <FileTextIcon className="h-12 w-12 mb-4 text-primary" />
            <h3 className="text-xl font-bold mb-2">Job Offer Generation</h3>
            <p className="text-muted-foreground">
              Create detailed job offers based on your CPF levels and tracks.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}


function PencilIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" />
      <path d="m15 5 4 4" />
    </svg>
  )
}

function SettingsIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M10 3a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0 6a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM17.5 17a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
      <path d="M19 19h-2.5a2 2 0 0 1-1-3.75V13a2 2 0 0 1 4 0v2.25a2 2 0 0 1-1 3.75H19z" />
    </svg>
  )
}

function FileTextIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
      <path d="M14 2h6M18 7h-5a2 2 0 0 1-2-2M12 12v2m0 4v2m0-4h2m-2 4h2M9 16h6" />
    </svg>
  )
}


const Pricing = ({ title, price, features, url, buttonText = "Get Started", isEnterprise = false }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        <div className="flex items-baseline justify-center">
          {isEnterprise ? (
            <span className="text-3xl font-bold">Custom</span>
          ) : (
            <>
              <span className="text-3xl font-bold">${price}</span>
              <span className="text-gray-500">/month</span>
            </>
          )}
        </div>
        <ul className="space-y-1 text-gray-500 text-sm">
          {features.map((feature, index) => (
            <li key={index}>
              <Check className="mr-2 inline-block h-4 w-4" />
              {feature}
            </li>
          ))}
        </ul>
      </CardContent>
      <CardFooter>
        <a
          href={url}
          className="w-full inline-flex h-10 items-center justify-center rounded-md bg-blue-500 px-8 text-sm font-medium text-white shadow transition-colors hover:bg-blue-600 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blue-600 disabled:pointer-events-none disabled:opacity-50"
        >
          {buttonText}
        </a>
      </CardFooter>
    </Card>
  );
};


const PricingSection = () => {
  return (
    <section id="pricing" className="w-full py-8 md:py-16 lg:py-20 bg-gray-100">
      <div className="container mx-auto px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center mb-12">
          <div className="space-y-2">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Choose Your Plan</h2>
            <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              From individual creators to enterprise solutions, we've got you covered.
            </p>
          </div>
        </div>
        <div className="mx-auto grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:max-w-5xl">
          <Pricing
            title='Free Tier'
            price={0}
            url={`${APP_URL}/register`}
            features={[
              'Single Framework',
              'Basic Customization',
              'Up to 3 job offer Generation',
              'Export to PDF',
              'Basic support',
            ]}
          />
          <Pricing
            title='Pro'
            price={29}
            url={`${APP_URL}/register`}
            features={[
              'Unlimited CPFs',
              'Unlimited job offers',
              'Advanced customization',
              'Export to any format',
              'Advanced support',
            ]}
          />
          <Pricing
            title="Enterprise"
            features={[
              "Create unlimited CPFs",
              "Generate job offers for unlimited levels",
              "Advanced customization",
              "Export to any format",
              "Dedicated support"
            ]}
            url={`${APP_URL}/register`}
            buttonText="Contact Sales"
            isEnterprise={true}
          />
        </div>
      </div>
    </section>
  );
};


const OverlappingImages = () => {
  return (
    <div className="relative w-full mx-auto">
      <img
        src="https://img.getimg.ai/generated/img-4kkmRJRiK9GN8XRG40K7e.jpeg"
        alt="Space exploration scene"
        className="w-full h-auto"
      />
    </div>
  );
};


const PresentationSection = () => {
  return (
    <section className="w-full py-4 md:py-8 lg:py-12 lg:py-20">
      <div className="container mx-auto space-y-12 px-4 md:px-6">
        <div className="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">

          <div className="flex flex-col justify-center space-y-4">
            <div className="space-y-2">
              <h1 className="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none">
                Revolutionize Your HR with AI
              </h1>
              <p className="max-w-[600px] text-muted-foreground md:text-xl">
                Essor helps companies create, manage, and optimize their HRs using advanced AI technology.
              </p>
            </div>
            <div className="flex flex-col gap-2 min-[400px]:flex-row">
              <a
                href={`${APP_URL}/register`}
                className="inline-flex bg-blue-500 text-white h-10 items-center justify-center rounded-md bg-primary px-8 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
              >
                Get Started
              </a>
            </div>
          </div>

          < OverlappingImages/>

        </div>
      </div>
    </section>
  );
};

const DemoSection = () => {
  return (
    <section id="samples" className="w-full py-8 md:py-16 lg:py-20 bg-gray-50">
      <div className="container mx-auto space-y-12 px-4 md:px-6">
        <div className="flex flex-col items-center justify-center space-y-4 text-center">
          <div className="space-y-2">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-gray-900">
              See Our AI in Action
            </h2>
            <p className="max-w-[900px] text-gray-600 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              Explore how Essor can help you create, manage, and optimize your Career Progression Frameworks.
            </p>
          </div>
        </div>
        <div className="flex justify-center w-full h-full">
          <div className="w-full max-w-3xl aspect-video">
            <iframe
              width="896"
              height="440"
              className="w-full rounded-lg shadow-lg"
              src="https://www.youtube.com/embed/764h7kFTluI?si=BZTl8MBm6WYFTaiI"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
          {[1, 2, 3].map((index) => (
            <div key={index} className="rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300">
              <div className="w-full aspect-video bg-gray-200" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};


export default function VideoPlatform() {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="px-4 lg:px-6 h-14 flex items-center">
        <img src="https://i.ibb.co/6ZtDkxr/essor-simple-logo.png" alt="Essor" className="h-6 w-6" />
        <span className="text-lg font-semibold ml-2">Essor</span>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <a href="#features" className="text-sm font-medium hover:underline underline-offset-4" onClick={(e) => {
            e.preventDefault();
            scrollToSection('features');
          }}>
            Features
          </a>
          <a href="#samples" className="text-sm font-medium hover:underline underline-offset-4" onClick={(e) => {
            e.preventDefault();
            scrollToSection('samples');
          }}>
            Demo
          </a>
          <a href="#pricing" className="text-sm font-medium hover:underline underline-offset-4" onClick={(e) => {
            e.preventDefault();
            scrollToSection('pricing');
          }}>
            Pricing
          </a>
        </nav>
      </header>

      <main className="flex-1">

        <PresentationSection />

        <FeaturesSection />

        <DemoSection />

        <PricingSection />

      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500">&copy; 2024 AI Video Platform. All rights reserved.</p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          {/* Footer navigation links can be added here if needed */}
        </nav>
      </footer>
    </div>
  );
}
