import React from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';

const faqs = [
  {
    question: "What is Essor?",
    answer: "Essor is an AI-powered Career Progression Framework (CPF) tool designed to help companies create, manage, and optimize their career frameworks. It uses advanced AI technology to streamline the process of developing and maintaining comprehensive CPFs tailored to your organization's needs."
  },
  {
    question: "How does Essor work?",
    answer: "Essor leverages AI to analyze your company's structure, industry standards, and best practices to generate a customized Career Progression Framework. You can then easily modify and adapt this framework using our intuitive interface. The system also helps you create job offers based on your CPF levels and tracks, and provides tools for managing your company profile and user feedback."
  },
  {
    question: "What are the key features of Essor?",
    answer: () => (
      <div>
        <p className="mb-4">Essor offers a range of powerful features, including:</p>
        <ul className="list-disc pl-5 mb-4">
          <li>AI-Generated CPFs</li>
          <li>Framework Customization</li>
          <li>Job Offer Generation</li>
          <li>Company Profile Management</li>
          <li>User Authentication</li>
          <li>Feedback System</li>
        </ul>
        <p>These features work together to provide a comprehensive solution for managing your organization's career progression framework.</p>
      </div>
    )
  },
  {
    question: "Is Essor suitable for companies of all sizes?",
    answer: "Yes, Essor is designed to be scalable and adaptable to companies of various sizes, from startups to large enterprises. The AI-powered system can generate frameworks that are appropriate for your organization's specific needs and can be easily customized as your company grows and evolves."
  },
  {
    question: "What upcoming features are planned for Essor?",
    answer: "We're constantly working to improve Essor. Some of the exciting features we're planning to implement include Performance Review Integration, External Data Integration (with HRIS and salary databases), Advanced Analytics for career progression insights, and a dedicated Mobile App for on-the-go CPF management."
  },
  {
    question: "How secure is the data stored in Essor?",
    answer: "We take data security very seriously. Essor implements robust user authentication systems and follows industry best practices for data protection. All sensitive information is encrypted, and we regularly update our security measures to ensure your company's data remains safe and confidential."
  },
  {
    question: "Can I integrate Essor with other HR systems?",
    answer: "Currently, Essor operates as a standalone system. However, we are working on developing integrations with popular HRIS, salary databases, and skills management systems. These integrations will be available in future updates to provide even more accurate and up-to-date frameworks."
  },
  {
    question: "How can I get started with Essor?",
    answer: "Getting started with Essor is easy! Simply click the 'Get Started' button on our homepage to create an account. Once you've signed up, you can begin by setting up your company profile and letting our AI generate an initial Career Progression Framework for you to customize and refine."
  },
];

function FAQItem({ question, answer }) {
  return (
    <Disclosure as="div" className="mt-4">
      {({ open }) => (
        <>
          <DisclosureButton className="flex justify-between w-full px-4 py-3 text-lg font-medium text-left text-gray-800 bg-white rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 transition-colors duration-200 shadow-sm">
            <span>{question}</span>
            {open ? (
              <ChevronUp className="w-5 h-5 text-blue-500" />
            ) : (
              <ChevronDown className="w-5 h-5 text-blue-500" />
            )}
          </DisclosureButton>
          <DisclosurePanel className="px-4 pt-4 pb-2 text-gray-600">
            {typeof answer === 'function' ? answer() : answer}
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}

function FAQPage() {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-50 to-indigo-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white p-8 rounded-xl shadow-lg">
        <h1 className="text-4xl font-bold text-center mb-4 text-gray-900">Essor</h1>
        <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Frequently Asked Questions</h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQPage;